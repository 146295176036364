import { MouseEvent, RefObject, TouchEvent, useEffect } from 'react';

type ClickableEvent<T extends HTMLElement> = MouseEvent<T> | TouchEvent<T>;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void,
  parent?: Document | HTMLElement,
): void => {
  useEffect(() => {
    const wrapper = parent || document;

    const listener = (event: Event) => {
      const castEvent = event as unknown as ClickableEvent<HTMLElement>;
      const el = ref.current;

      if (
        !el ||
        el.contains(castEvent.target as Node) ||
        el.id === (castEvent.target as HTMLElement).dataset.id
      ) {
        return;
      }

      handler();
    };

    wrapper.addEventListener('click', listener);

    return () => {
      wrapper.removeEventListener('click', listener);
    };
  }, [ref, handler, parent]);
};

export default useOnClickOutside;
